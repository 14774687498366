<template>
  <div class="consultation-item">
    <div class="d-flex justify-content-between align-items-center">
      <div class="specialization">
        Заказ {{ order.order.number }}
      </div>
      <div class="crm-date">
        {{ date }}
      </div>
    </div>
    <div class="doctor mt-2">
      {{ order.branch.name }}
    </div>
    <div
      class="crm-status mt-2"
    >
      <span>
        Статус:
      </span>
      <span
        class="status-text"
        :class="colored"
      >
        {{ orderStatus }}
      </span>
    </div>
  </div>
</template>

<script>
import { format, parseJSON } from '@evd3v/date-fns';

export default {
  name: 'PatientDetailOrdersItem',
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    date() {
      return format(parseJSON(this.order.order.createdAt), 'dd.MM.yyyy HH:mm');
    },
    orderStatus() {
      switch (this.order.order.status) {
        case 'WaitPay':
          return 'Ожидает оплаты';
        case 'Paid':
          return 'Оплачен';
        case 'Completed':
          return 'Готов';
        case 'Canceled':
          return 'Отменен';
        case 'Received':
          return 'Размещен у партнера';
        case 'InProgress':
          return 'В процессе выполнения';
        default:
          return '-';
      }
    },
    colored() {
      switch (this.order.order.status) {
        case 'Paid': // Оплачен
          return 'text-black-50';
        case 'Completed': // Готов
          return 'text-success';
        case 'Canceled': // Отменен
          return 'text-danger';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.consultation-item {
  cursor: pointer;
  padding: 20px 10px;
  width: 100%;
  border-bottom: 1px solid #E0E0E0;

  &.active {
    background: #6E88F3;
    color: #ffffff;

    .specialization {
      color: #ffffff;
    }
    .crm-status {
      color: #ffffff;
    }
    .status-text {
      color: #ffffff!important;
    }
  }

  .crm-id, .crm-date {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
  }
  .specialization {
    font-size: 14px;
    line-height: 18px;
    color: #4B62C1;
  }
  .doctor {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .crm-status {
    font-size: 14px;
    line-height: 18px;
    color: #908F8D;
  }

  .status-text {
    font-weight: 500;
  }
}
</style>
