<template>
  <div class="analyzes-info">
    <div
      v-if="!ordersList.length && isAllItemsLoaded"
      class="no-consultations"
    >
      <span>Нет анализов</span>
    </div>
    <template v-else>
      <async-list
        :is-all-items-loaded="isAllItemsLoaded"
        :disable-toggle-header="true"
        class="consultations-list"
        @load-more-items="loadMoreItems"
      >
        <div
          v-for="order in ordersList"
          :key="order.order.id"
        >
          <patient-detail-orders-item
            :order="order"
            :class="{ active: order.order.id === activeOrder?.order.id }"
            @click.native="setActiveOrder(order)"
          />
        </div>
      </async-list>

      <patient-detail-order-detail
        v-if="activeOrderData"
        :order="activeOrderData"
        :is-self-created="activeOrder.order.isSelfCreated"
      />
    </template>
  </div>
</template>

<script>
import AsyncList from '@/components/AsyncList.vue';
import PatientDetailOrdersItem from '@/components/Patients/PatientDetailOrdersItem.vue';
import PatientDetailOrderDetail from '@/components/Patients/PatientDetailOrderDetail.vue';
import { api } from '@/helpers/api';
import NProgress from 'nprogress';

export default {
  name: 'PatientDetailAnalyzes',
  components: {
    PatientDetailOrderDetail,
    PatientDetailOrdersItem,
    AsyncList,
  },
  props: {
    patientId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      limit: 15,
      ordersList: [],
      activeOrder: null,
      activeOrderData: null,
      isLoading: false,
      isAllItemsLoaded: false,
    };
  },
  computed: {
    totalCount() {
      return this.ordersList.length;
    },
  },
  watch: {
    async activeOrder(newVal) {
      if (newVal) await this.fetchOrderData(newVal.order.id);
    },
  },
  async created() {
    await this.fetchOrders();
  },
  methods: {
    async fetchOrders() {
      NProgress.start();
      const prevTotal = this.totalCount;
      try {
        this.isLoading = true;
        const params = new URLSearchParams();
        params.append('PatientId', this.patientId);
        params.append('Limit', this.limit);
        params.append('Offset', prevTotal);
        const { data } = await api.get('v2/person/analyses', { params });
        this.ordersList = this.ordersList.concat(data);
        if (prevTotal + this.limit > this.totalCount) {
          this.isAllItemsLoaded = true;
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
        NProgress.done();
      }
    },
    async fetchOrderData(orderId) {
      const response = await this.$store.dispatch(
        this.$types.GET_LABORATORY_ORDER_FOR_ID,
        {
          orderId,
          save: true,
        },
      );
      this.activeOrderData = response.data;
    },
    setActiveOrder(order) {
      this.activeOrder = { ...order };
    },
    async loadMoreItems() {
      if (!this.isLoading && !this.isAllItemsLoaded) {
        await this.fetchOrders();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.analyzes-info {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  overflow: auto;
  box-sizing: border-box;
  height: 100%;
}
.consultations-list {
  height: 100%;
  overflow: auto;
  width: 280px;
  border-right: 1px solid #e0e0e0;
  box-sizing: border-box;
  flex-shrink: 0;
}
.no-consultations {
  padding: 30px 15px 30px 30px;
  font-size: 16px;
  line-height: 20px;
  color: #908f8d;
}
</style>
