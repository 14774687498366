<template>
  <div class="consultation-detail">
    <custom-scrollbar>
      <div class="crm-inner">
        <div class="flex justify-between gap-3 w-100">
          <div>
            <p class="crm-title mb-2">
              Лаборатория: {{ order.branch.legalPartner.legalName }}
            </p>
            <div class="font-bold laboratory-address mb-2">
              {{ order.branch.address }}
            </div>
          </div>
          <div class="flex gap-1 order-date">
            <span>{{ date }}</span>
            <span>{{ time }}</span>
          </div>
        </div>
        <div class="row gap-3 w-100 mb-2">
          <div class="col gap-1 laboratory-contact">
            <span class="contact-title">Телефон:</span>
            <span class="contact-value ml-2">{{
              order.branch.legalPartner.partner.contactPhoneNumber
            }}</span>
          </div>
          <div class="col gap-1 laboratory-contact">
            <span class="contact-title">Почта:</span>
            <span class="contact-value ml-2">{{
              order.branch.legalPartner.createOrderGuaranteeEmailAddresses[0]
            }}</span>
          </div>
        </div>

        <div class="crm-line" />
        <div class="my-1 d-flex align-items-baseline gap-2">
          <div class="crm-subtitle">
            Номер заказа
          </div>
          <div class="value font-weight-bold">
            {{ order.number }}
          </div>
        </div>
        <div>
          <b-table
            :fields="fields"
            :items="orderItems"
            :small="true"
            bordered
            class="bg-white border-secondary"
            hover
          >
            <template #cell(cost)="row">
              <span v-if="row.item.cost !== null">
                {{ `${row.item.cost}р` }}
              </span>
            </template>
            <template #custom-foot="{ items }">
              <b-tr>
                <b-td colspan="2">
                  <span class="analysis-total">
                    Общая стоимость
                  </span>
                </b-td>
                <b-td class="text-center">
                  {{ items.reduce((acc, cur) => acc + cur.cost, 0) }}p
                </b-td>
              </b-tr>
            </template>
          </b-table>
        </div>
        <div class="d-flex align-items-baseline gap-6">
          <div class="crm-column">
            <div
              class="crm-status"
              :class="statusColor"
            >
              {{ statusText }}
            </div>
          </div>
          <div class="crm-column">
            <div class="crm-subtitle">
              Использованный промокод:
            </div>
            <div class="value crm-bold">
              {{ promocode }}
            </div>
          </div>
          <div class="d-flex align-items-baseline gap-2">
            <div class="crm-subtitle">
              Создан из направления:
            </div>
            <div class="value crm-bold">
              {{ isSelfCreated ? 'Нет' : 'Да' }}
            </div>
          </div>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { format, parseISO } from '@evd3v/date-fns';

export default {
  name: 'PatientDetailOrderDetail',
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
    isSelfCreated: Boolean,
  },
  data() {
    return {
      fields: [
        {
          key: 'service.code',
          label: 'Код анализа',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'service.name',
          label: 'Наименование анализа',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'cost',
          label: 'Цена',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
      ],
    };
  },
  computed: {
    promocode() {
      if (this.order.promoCode && this.order.promoCode.code !== null) {
        return this.order.promoCode.code;
      }
      return '-';
    },
    date() {
      return format(parseISO(this.order.createdAt), 'dd.MM.yyyy');
    },
    time() {
      return format(parseISO(this.order.createdAt), 'HH:mm');
    },
    orderItems() {
      if (this.order.additionalItems) {
        return this.order.basicItems.concat(
          this.order.additionalItems.map((item) => ({
            ...item,
            service: { ...item.partnerService },
          })),
        );
      }
      return this.order.basicItems;
    },
    statusText() {
      switch (this.order.status) {
        case 'WaitPay':
          return 'Ожидает оплаты';
        case 'Paid':
          return 'Оплачен';
        case 'Completed':
          return 'Готов';
        case 'Canceled':
          return 'Отменен';
        case 'Received':
          return 'Размещен у партнера';
        case 'InProgress':
          return 'В процессе выполнения';
        default:
          return '-';
      }
    },
    statusColor() {
      switch (this.order.status) {
        case 'Completed':
          return 'green';
        case 'Canceled':
          return 'red';
        default:
          return 'gray';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.contact-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

.contact-value {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #707070;
}

.analysis-total {
  font-weight: 600;
}

.consultation-detail {
  height: 100%;
  flex: 1;
}

.crm-inner {
  height: 100%;
  width: 100%;
  padding: 15px 20px;
}
.crm-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 30px;
}
.crm-subtitle {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
  color: #707070;
}

.laboratory-address {
  color: #707070;
}

.order-date {
  color: #707070;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.crm-line {
  height: 1px;
  background-color: #e0e0e0;
  width: 100%;
}

.crm-column {
 width: 240px;
}

.crm-status {
  text-align: center;
  border-radius: 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  padding: 0 10px;

  &.green {
    background: #e6ffd1;
    color: $green;
  }
  &.gray {
    background: $gray;
    color: $gray-dark;
  }
  &.red {
    background: #ffdede;
    color: $red;
  }
}
</style>
